function burgerMenu() {
    //burger menu
    const burgerBtn = document.querySelector(".header_burger");
    const navMenu = document.querySelector(".nav_list");

    burgerBtn.addEventListener("click", () => {
        burgerBtn.classList.toggle("active");
        navMenu.classList.toggle("active");
        document.querySelector("body").classList.toggle("lock");
    });
}

export default burgerMenu;
