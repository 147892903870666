"use strcit";

import activeSectionMenu from "./modules/active-section-menu";
import burgerMenu from "./modules/burger-menu";
import fileInput from "./modules/file-input";
import form from "./modules/form";
import pauseVideoOnPlay from "./modules/pause-video-onPlay";
import scrollHeader from "./modules/scroll-header";
import swiperSettings from "./modules/swiper-settings";
import videojsPlayers from "./modules/videojs-players";

window.addEventListener("DOMContentLoaded", (event) => {
    burgerMenu();
    activeSectionMenu();
    scrollHeader();
    swiperSettings();
    videojsPlayers();
    pauseVideoOnPlay();
    fileInput();
    form();
});
