function pauseVideoOnPlay() {
    let videoPlaying = null;

    const videos = document.getElementsByClassName("vjs-tech");
    for (let i = 0; i < videos.length; i++) {
        videos[i].addEventListener("play", function () {
            if (videoPlaying && videoPlaying != this) {
                videoPlaying.pause();
            }
            videoPlaying = this;
        });
    }
}

export default pauseVideoOnPlay;
