function scrollHeader() {
    //scroll header
    function headerScroll() {
        const nav = document.getElementById("header");
        const logoDark = document.querySelector(".vvt-logo_dark");
        const logoWhite = document.querySelector(".vvt-logo_white");

        if (this.scrollY <= 80) {
            nav.classList.remove("scroll-header");
            logoWhite.classList.remove("logo-hide");
            logoDark.classList.add("logo-hide");
        } else {
            nav.classList.add("scroll-header");
            logoWhite.classList.add("logo-hide");
            logoDark.classList.remove("logo-hide");
        }
    }
    window.addEventListener("scroll", headerScroll);
}

export default scrollHeader;
