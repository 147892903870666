function fileInput() {
    let fileInput = document.getElementById("file-input");
    let fileInfo = document.getElementById("contact_file_info");
    let fileList = document.getElementById("contact_file_list");

    fileInput.addEventListener("change", () => {
        fileList.innerHTML = "";
        fileInfo.textContent = `${fileInput.files.length} File selected`;

        for (let i of fileInput.files) {
            let reader = new FileReader();
            let listItem = document.createElement("li");
            let fileName = i.name;
            let fileSize = (i.size / 1024).toFixed(1);
            listItem.innerHTML = `<p>${fileName}</p><p>${fileSize}KB</p>`;
            if (fileSize >= 1024) {
                fileSize = (fileSize / 1024).toFixed(1);
                listItem.innerHTML = `<p>${fileName}</p><p>${fileSize}MB</p>`;
            }
            fileList.appendChild(listItem);
        }
    });
}

export default fileInput;
