function activeSectionMenu() {
    //menu active section
    const burgerBtn = document.querySelector(".header_burger");
    const navMenu = document.querySelector(".nav_list");
    const navLinks = document.querySelectorAll(".nav_list_item_link");
    navLinks.forEach((link) => {
        link.addEventListener("click", () => {
            navLinks.forEach((item) => {
                item.classList.remove("active");
            });
            link.classList.add("active");
            navMenu.classList.toggle("active");
            burgerBtn.classList.toggle("active");
            document.querySelector("body").classList.toggle("lock");
        });
    });
}

export default activeSectionMenu;
