function formReset() {
    document.getElementById("name").value = "";
    document.getElementById("email").value = "";
    document.getElementById("position").value = "";
    document.getElementById("source").value = "";
    document.getElementById("message").value = "";
    document.getElementById("file-input").value = "";

    let fileInfo = document.getElementById("contact_file_info");
    let fileList = document.getElementById("contact_file_list");

    if (document.contains(fileInfo) || document.contains(fileList)) {
        fileInfo.innerHTML = "No Files Chosen (Max size: 15MB)";
        fileList.innerHTML = "";
    }
}

export default formReset;
