function videojsPlayers() {
    let player1 = videojs("my-video1", {
        controlBar: {
            volumePanel: false,
            muteToggle: true,
            progressControl: false,
            remainingTimeDisplay: false,
            fullscreenToggle: false,
        },
    });
    let player2 = videojs("my-video2", {
        controlBar: {
            volumePanel: false,
            muteToggle: true,
            progressControl: false,
            remainingTimeDisplay: false,
            fullscreenToggle: false,
        },
    });
    let player3 = videojs("my-video3", {
        controlBar: {
            volumePanel: false,
            muteToggle: true,
            progressControl: false,
            remainingTimeDisplay: false,
            fullscreenToggle: false,
        },
    });
}

export default videojsPlayers;
