import formReset from "./form-reset";

const validation = (nm, em, pos, src, msg, fm) => {
    const data = [nm, em, pos, src];
    const label = document.getElementById("file-label");

    for (let i = 0; i < data.length; i++) {
        if (data[i].value.trim() === "") {
            data[i].classList.add("validation-error");
            return false;
        }
    }

    if (fm.files.length === 0) {
        label.focus();
        label.classList.add("file-error");
        return false;
    }

    for (let i = 0; i <= data.length - 1; i++) {
        data[i].classList.remove("validation-error");
    }
    label.classList.remove("file-error");
    return true;
};

function form() {
    const form = document.getElementById("form");

    form.addEventListener("submit", (event) => {
        event.preventDefault();

        const name = document.getElementById("name");
        const email = document.getElementById("email");
        const position = document.getElementById("position");
        const source = document.getElementById("source");
        const message = document.getElementById("message");
        const formFileInput = document.getElementById("file-input");
        const button = document.getElementById("submit_btn");
        const status = document.getElementById("status");

        if (
            validation(
                name,
                email,
                position,
                source,
                message,
                formFileInput
            ) === true
        ) {
            button.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><style>.spinner_0XTQ{transform-origin:center;animation:spinner_y6GP .75s linear infinite}@keyframes spinner_y6GP{100%{transform:rotate(360deg)}}</style><path class="spinner_0XTQ" d="M12,23a9.63,9.63,0,0,1-8-9.5,9.51,9.51,0,0,1,6.79-9.1A1.66,1.66,0,0,0,12,2.81h0a1.67,1.67,0,0,0-1.94-1.64A11,11,0,0,0,12,23Z" fill="#ffffff"/></svg>`;
            const options = {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "content-type": "application/json",
                    authorization:
                        "Bearer 1/1202135807120514:3848ded218e3879d28a10b4df26b0954",
                },
                body: JSON.stringify({
                    data: {
                        name: name.value,
                        notes: `Name: ${name.value}\nEmail: ${
                            email.value
                        }\nPosition: ${
                            position.value
                        }\nHe/she has heard about us from: ${
                            source.value
                        }\nMessage: ${
                            message.value.trim() === ""
                                ? "No message"
                                : message.value
                        }`,
                        projects: ["1205195062536205"],
                    },
                }),
            };

            fetch("https://app.asana.com/api/1.0/tasks", options)
                .then((response) => response.json())
                .then((response) => {
                    const form = new FormData();
                    form.append("parent", response.data.gid);
                    form.append("file", formFileInput.files[0]);

                    const options = {
                        method: "POST",
                        headers: {
                            accept: "application/json",
                            authorization:
                                "Bearer 1/1202135807120514:3848ded218e3879d28a10b4df26b0954",
                        },
                    };

                    options.body = form;

                    fetch("https://app.asana.com/api/1.0/attachments", options)
                        .then((response) => response.json())
                        .then((response) => {
                            formReset();
                            button.innerHTML = "Send Message";
                            status.innerHTML = `<div class='contact_msg_status'>
                                                    <h6 class='success'>The application was successfully sent!</h6>
                                                </div>`;
                            setTimeout(() => {
                                status.innerHTML = "";
                            }, 8000);
                        })
                        .catch((err) => {
                            status.innerHTML = `<div class='contact_msg_status'>
                                                    <h6 class='error'>Sending Error from server. Please try again!</h6>
                                                </div>`;
                            setTimeout(() => {
                                status.innerHTML = "";
                            }, 8000);
                            console.error(err);
                            formReset();
                            button.innerHTML = "Send Message";
                        });
                })
                .catch((err) => {
                    status.innerHTML = `<div class='contact_msg_status'>
                                                    <h6 class='error'>Sending Error from server. Please try again!</h6>
                                                </div>`;
                    setTimeout(() => {
                        status.innerHTML = "";
                    }, 8000);
                    console.error("Task creation Error: ", err);
                    formReset();
                    button.innerHTML = "Send Message";
                });
        }
    });
}

{
    /*  */
}

export default form;
